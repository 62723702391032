body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  padding: 20px 40px;
}

a {
  text-decoration: none;
  color: black;
  width: 50px;
  padding: 25px;
  box-sizing: border-box;
}
